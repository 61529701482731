import React from "react";
import Paragraph from "../../common/Paragraph";
import FormLabel from "../../common/form-components/FormLabel";
import InputType from "../../common/form-components/InputType";

function PaymentInfo() {
  return (
    <div className="md:pt-10 pt-7 w-full">
      <Paragraph text20 className="font-medium mb-4">
        Payment Info.
      </Paragraph>
      <div className="grid grid-cols-12 lg:gap-6 gap-3 mb-4">
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            Name of card<span className="text-site-red">*</span>
          </FormLabel>
          <InputType name="name-card" placeholder="Enter name of card" />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            Card number<span className="text-site-red">*</span>
          </FormLabel>
          <InputType
            type="password"
            name="card-number"
            placeholder="Enter card number"
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            Expiration Date<span className="text-site-red">*</span>
          </FormLabel>
          <InputType
            type="date"
            name="expiration-date"
            placeholder="Enter expiration date"
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            CVV<span className="text-site-red">*</span>
          </FormLabel>
          <InputType type="number" name="cvv" placeholder="Enter CVV" />
        </div>
      </div>
    </div>
  );
}

export default PaymentInfo;
