import React from "react";
import Paragraph from "./Paragraph";

function Breadcrumb() {
  return (
    <ul className="flex items-center gap">
      <li className="pr-3 mr-1 relative before:absolute before:right-0 before:content-['/'] before:text-dark-grey">
        <a href="" className="text-dark-grey text-sm font-medium">
          Profile
        </a>
      </li>
      <li>
        <Paragraph text14 className=" ">
          Hiboux sur I'arbre
        </Paragraph>
      </li>
    </ul>
  );
}

export default Breadcrumb;
