import React from "react";
import IconSuccess from "../../assets/images/icon_check_mark.svg";
import Paragraph from "../../common/Paragraph";
import Button from "../../common/Button";
import { IoCloseOutline } from "react-icons/io5";

type PaymentSuccessProps = {
  onClose: () => void; // onClose is a function that returns void
};

function PaymentSuccess({ onClose }: PaymentSuccessProps) {
  return (
    <div className="text-center">
      <span className="absolute right-5 top-5 cursor-pointer" onClick={onClose}>
        <IoCloseOutline size="24" color="#333333" />
      </span>
      <div className="md:mb-30 mb-5 text-center inline-block xl:max-w-[126px] md:max-w-[100px] max-w-[80px]">
        <img src={IconSuccess} alt="icon-success" />
      </div>
      <Paragraph text24 className="mb-4">
        Payment Successful!!!
      </Paragraph>
      <Paragraph text20 className="mb-30 font-normal !leading-[30px]">
        Thank you for your purchase. You will be redirected to profile page
        shortly.
      </Paragraph>
      <Button primary>Explore More</Button>
    </div>
  );
}

export default PaymentSuccess;
