import React, { useState } from "react";
import Paragraph from "../../common/Paragraph";
import FormLabel from "../../common/form-components/FormLabel";
import InputType from "../../common/form-components/InputType";
import Select from "react-select";
import SwitchToggle from "../../common/form-components/SwitchToggle";

function ShippingAddress() {
  const [selectedOption, setSelectedOption] = useState("");
  const [isBillingSame, setIsBillingSame] = useState(false);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
  };
  // Define form submission handler
  const handleSubmit = (values: typeof initialValues) => {
    console.log("Form submitted with values:", values);
  };
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const handleChange = () => {
    console.log(selectedOption);
  };
  return (
    <div className="md:pt-10 pt-7 w-full">
      <Paragraph text20 className="font-medium mb-4">
        Shipping Address
      </Paragraph>
      <div className="grid grid-cols-12 lg:gap-6 gap-3 mb-4">
        <div className="col-span-12">
          <FormLabel>
            Full address<span className="text-site-red">*</span>
          </FormLabel>
          <InputType name="full-address" placeholder="Enter full address" />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            City<span className="text-site-red">*</span>
          </FormLabel>
          <InputType name="city" placeholder="Enter city" />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            Post code<span className="text-site-red">*</span>
          </FormLabel>
          <InputType name="city" placeholder="Enter post code" />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            Country<span className="text-site-red">*</span>
          </FormLabel>
          <Select
            unstyled
            onChange={handleChange}
            options={options}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select country"
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            Contact number<span className="text-site-red">*</span>
          </FormLabel>
          <div className="flex items-stretch w-full border rounded-lg focus-within:border-dark-grey">
            <div className="pl-[15px] pr-[10px] py-[11px] border-medium-grey relative before:absolute before:right-0 before:h-5 before:w-[1px] before:bg-medium-grey before:rounded-[2px] ">
              <select
                name="phone_country_code"
                className="text-base leading-[15px] font-normal bg-transparent"
              >
                <option value={"+33"}>+33</option>
                <option value={"+91"}>+91</option>
              </select>
            </div>
            <InputType
              type={"number"}
              fullWidth
              name={"phone_number"}
              placeholder={"Type here"}
              className={"border-0"}
            />
          </div>
        </div>
      </div>
      <SwitchToggle
        rightLabel="Delivery address is the same as the billing address"
        onChange={() => setIsBillingSame(!isBillingSame)}
      />
      {isBillingSame && (
        <div className="mt-30">
          <Paragraph text20 className="font-medium mb-4">
            Billing Address
          </Paragraph>
          <div className="grid grid-cols-12 lg:gap-6 gap-3">
            <div className="col-span-12">
              <FormLabel>
                Full address<span className="text-site-red">*</span>
              </FormLabel>
              <InputType name="full-address" placeholder="Enter full address" />
            </div>
            <div className="md:col-span-6 col-span-12">
              <FormLabel>
                City<span className="text-site-red">*</span>
              </FormLabel>
              <InputType name="city" placeholder="Enter city" />
            </div>
            <div className="md:col-span-6 col-span-12">
              <FormLabel>
                Post code<span className="text-site-red">*</span>
              </FormLabel>
              <InputType name="city" placeholder="Enter post code" />
            </div>
            <div className="md:col-span-6 col-span-12">
              <FormLabel>
                Country<span className="text-site-red">*</span>
              </FormLabel>
              <Select
                unstyled
                onChange={handleChange}
                options={options}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select country"
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <FormLabel>
                Contact number
                <span className="text-site-red">*</span>
              </FormLabel>
              <div className="flex items-stretch w-full border rounded-lg focus-within:border-dark-grey">
                <div className="pl-[15px] pr-[10px] py-[11px] border-medium-grey relative before:absolute before:right-0 before:h-5 before:w-[1px] before:bg-medium-grey before:rounded-[2px] ">
                  <select
                    name="phone_country_code"
                    className="text-base leading-[15px] font-normal bg-transparent"
                  >
                    <option value={"+33"}>+33</option>
                    <option value={"+91"}>+91</option>
                  </select>
                </div>
                <InputType
                  type={"number"}
                  fullWidth
                  name={"phone_number"}
                  placeholder={"Type here"}
                  className={"border-0"}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ShippingAddress;
