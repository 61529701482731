// src/store.ts
import { Middleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import rootReducers from "./rootReducers";

const middlewares: Middleware[] = []; // Define any custom middlewares here

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable state invariant checks if using non-serializable values
    }).concat(middlewares),
});

export const persistor = persistStore(store);

const exportStore = { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default exportStore;
