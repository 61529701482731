import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import NotFound from "../components/notfound/NotFound";
import { RoutesPath } from "./Routes";
import { paths } from "./Paths";
import { CheckUser } from "../services/profileService";
import Wrapper from "../components/wrapper/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../redux/slices/userSlice";
import { setProfile } from "../redux/slices/profileSlice";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../redux/slices/siteLoaderSlice";
import Loader from "../common/Loader";
import { Interest } from "../interface/Interest";

const AppRoutes = () => {
  const [isValid, setIsValid] = useState(false);
  const [checkedToken, setCheckedToken] = useState(false); // Track whether token check is complete
  let location = useLocation();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location?.search);
  const token = searchParams.get("token");
  const isLoading = useSelector(siteLoaderSelector); // Get the loader state

  useEffect(() => {
    const validateToken = async () => {
      if (token && token.trim()) {
        dispatch(setToken(token));
        dispatch(showLoader()); // Show loader before API call

        try {
          const data = { token };
          const response = await CheckUser(data);

          if (response?.data?.data) {
            let responseData = response?.data?.data;
            let data = {
              avatar: responseData?.avatar,
              country: responseData?.country,
              followers: responseData?.followers,
              nickname: responseData?.nickname,
              description: responseData?.description,
              post: responseData?.totalPublicationsCount,
              id: responseData?.id,
              interest: responseData?.interest?.map((item: Interest) => ({
                id: item.id,
                interest: {
                  title: item.interest.title,
                  interestCategoryId: item.interest.interestCategoryId,
                  status: item.interest.status,
                },
              })),
            };
            dispatch(setProfile(data));
            setIsValid(true);
          } else {
            setIsValid(false);
          }
        } catch (error) {
          console.error("Error validating token:", error);
          setIsValid(false);
        } finally {
          dispatch(hideLoader()); // Hide loader after API call
        }
      } else {
        setIsValid(false);
      }
      setCheckedToken(true); // Mark token check as complete
    };

    validateToken();
  }, [token, dispatch]);

  // Only render Routes when token check is complete
  return (
    <>
      {isLoading && <Loader />} {/* Show loader if loading */}
      {checkedToken && (
        <Routes>
          {isValid ? (
            <>
              <Route element={<Wrapper />}>
                <Route
                  path="/"
                  element={<Navigate to={paths.web.profile} replace />}
                />
                {RoutesPath.map((route, id) => (
                  <Route
                    key={id}
                    path={route.path}
                    element={<route.element />}
                  />
                ))}
              </Route>
              <Route path="*" element={<NotFound />} />
            </>
          ) : (
            <Route path="*" element={<NotFound />} />
          )}
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
