import React, { useEffect, useState } from "react";
import moment from "moment";
import { FaPlayCircle, FaThumbsUp } from "react-icons/fa";
import { IoMdChatboxes } from "react-icons/io";
import { LuRepeat2 } from "react-icons/lu";
import { profileSelector } from "../../redux/slices/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getFeedById } from "../../services/profileService";
import NoDataFound from "../../assets/images/no_data_found.svg";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { FeedInterface } from "../../interface/Feed";
import InfiniteScroll from "react-infinite-scroll-component";
import DummyProfile from "../../assets/images/dummy_logo.png";
import Paragraph from "../../common/Paragraph";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";

function Feed() {
  const profileData = useSelector(profileSelector);
  const [feedData, setFeedData] = useState<FeedInterface[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [showMore, setShowMore] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const [imageClass, setImageClass] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const maxCharacters = 150; // Max characters for truncated text

  const timeDifference = (date: string) => {
    const today = moment();
    const createdDate = moment(date);
    let diff = today.diff(createdDate, "years");
    let message = "years ago";
    if (diff === 0) {
      diff = today.diff(createdDate, "months");
      message = "months ago";
      if (diff === 0) {
        diff = today.diff(createdDate, "weeks");
        message = "weeks ago";
        if (diff === 0) {
          diff = today.diff(createdDate, "days");
          message = "days ago";
          if (diff === 0) {
            diff = today.diff(createdDate, "hours");
            message = "hours ago";
            if (diff === 0) {
              diff = today.diff(createdDate, "minutes");
              message = "minutes ago";
            }
          }
        }
      }
    }
    if (diff === 0) {
      return "just now";
    }
    return `${diff} ${message}`;
  };

  const fetchFeed = async (page: number) => {
    if (profileData?.id) {
      let queryString = `?type=1&filter=0&page=${page}&limit=10&keyword=`;
      dispatch(showLoader());

      try {
        const response = await getFeedById(profileData.id, queryString);
        const count = response?.data?.total || 0;
        if (response?.data?.publications) {
          setFeedData((prevData) => [
            ...prevData,
            ...response.data.publications,
          ]);
          setHasMore(page < Math.ceil(count / itemsPerPage));
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    } else {
      console.warn("profileData.id is undefined, skipping API call.");
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchFeed(currentPage);
  }, [currentPage, profileData?.id]);

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const openLightbox = (videoUrl: string) => {
    console.log("Opening lightbox with video URL:", videoUrl);

    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const openImgLightbox = (imgUrl: string) => {
    console.log("Opening lightbox with video URL:", imgUrl);

    setImgUrl(imgUrl);
    setImageLightboxOpen(true);
  };

  const closeImgLightbox = () => {
    setImageLightboxOpen(false);
    setImgUrl(undefined);
  };

  return (
    <div>
      <InfiniteScroll
        dataLength={feedData?.length}
        next={() => {
          if (hasMore) {
            setCurrentPage((prevPage) => prevPage + 1); // Increment page number
          }
        }}
        hasMore={hasMore}
        loader={<h4>Loading ... </h4>}
      >
        {feedData?.length === 0 ? (
          <div className="break-inside-avoid text-center">
            <img
              src={NoDataFound}
              alt="no-data-found"
              className="mb-4 inline-block"
            />
            <Paragraph text24 className="mb-4 font-semibold">
              Sorry!
            </Paragraph>
            <Paragraph text20 className="font-normal">
              We haven’t found any data
            </Paragraph>
          </div>
        ) : (
          <ul>
            {feedData?.map((item: FeedInterface) => {
              const toggleShowMore = () => {
                setShowMore(!showMore);
              };
              const truncatedText = item?.text?.contentText?.slice(
                0,
                maxCharacters
              );
              const shouldTruncate =
                item?.text?.contentText?.length > maxCharacters;
              const img = new Image();
              img.src = item?.video?.imageUrl;
              img.onload = () => {
                const { width, height } = img;
                console.log("fsfsdf", img, width, height);
                if (width > height) {
                  setImageClass(
                    "max-h-[390px] w-full rounded-[20px] bg-light-grey "
                  );
                } else {
                  setImageClass(
                    "max-h-[390px] h-full rounded-[20px] bg-light-grey"
                  );
                }
              };
              {
                console.log("FDgfdfgdf1", imageClass);
              }

              return (
                <li
                  key={item.id}
                  className="relative block pb-[30px] mb-[30px] border-b border-medium-grey md:ps-[66px] ps-[40px] pt-[10px]"
                >
                  <div className="flex items-center absolute left-0 top-0 z-[1]">
                    <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                      <img
                        src={getProfileImage(item?.user?.avatar)}
                        alt="profile-photo"
                      />
                    </div>
                    <div className="bg-white flex items-center gap-4 px-5 md:py-3 py-2 rounded-se-[20px] rounded-ee-[20px] relative -left-[15px] -z-[1]">
                      <Paragraph text22>@{item?.user?.nickname}</Paragraph>
                      <Paragraph
                        text18
                        className="!leading-[17px] font-normal !text-dark-grey"
                      >
                        {timeDifference(item?.createdAt)}
                      </Paragraph>
                    </div>
                  </div>
                  <div className="relative pb-6">
                    <div className="block rounded-[20px] overflow-hidden relative w-auto max-h-[390px] ">
                      {item?.video ? (
                        <div
                          style={{
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          <img
                            id={`image-${item.id}`}
                            src={item?.video?.imageUrl}
                            className={imageClass}
                          />
                          <span className="absolute inset-0 flex items-center justify-center cursor-pointer">
                            <FaPlayCircle
                              size={40}
                              className="text-base"
                              onClick={() =>
                                openLightbox(item?.video?.videoUrl)
                              }
                            />
                          </span>
                        </div>
                      ) : (
                        <img
                          src={item?.images && item?.images[0]?.imageUrl}
                          alt="feed-img"
                          onClick={() => {
                            const imageUrl = item?.images?.[0]?.imageUrl;
                            if (imageUrl) {
                              openImgLightbox(imageUrl);
                            }
                          }}
                          className="max-h-[390px] h-full rounded-[20px] bg-light-grey cursor-pointer"
                        />
                      )}
                    </div>
                    <div className="flex items-center gap-5 absolute bottom-0">
                      <button className="bg-site-green cursor-default rounded-full w-[30px] h-[30px] flex items-center justify-center relative">
                        <FaThumbsUp
                          size={14}
                          color="#fff"
                          className="cursor-default"
                        />
                        {item?.countLikes > 0 && (
                          <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[10px] -right-[10px]">
                            {item?.countLikes}
                          </span>
                        )}
                      </button>
                      <button className="bg-site-yellow rounded-full cursor-default w-[30px] h-[30px] flex items-center justify-center relative">
                        <IoMdChatboxes
                          size={18}
                          color="#fff"
                          className="cursor-default"
                        />
                        {item?.countComments > 0 && (
                          <span className="bg-site-red min-w-[18px] cursor-default text-white text-xs leading-[11px] rounded-xl p-1 pb-[2px] absolute -top-[10px] -right-[10px]">
                            {item?.countComments}
                          </span>
                        )}
                      </button>
                    </div>
                  </div>

                  <Paragraph text18 className="mt-4 !font-normal">
                    <span>
                      {showMore || !shouldTruncate
                        ? item?.text?.contentText
                        : truncatedText}
                      {shouldTruncate && (
                        <button
                          onClick={toggleShowMore}
                          className="text-site-black font-medium  ml-1 inline"
                          style={{ cursor: "pointer" }}
                        >
                          {showMore ? "Read less" : "Read more..."}
                        </button>
                      )}
                    </span>
                  </Paragraph>
                </li>
              );
            })}
          </ul>
        )}
      </InfiniteScroll>

      {/* Lightbox for video */}
      {lightboxOpen && (
        <div className={feedData?.length === 1 ? "lightbox-no-navigation" : ""}>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
      {imageLightboxOpen && (
        <div className={feedData?.length === 1 ? "lightbox-no-navigation" : ""}>
          <Lightbox
            open={imageLightboxOpen}
            close={closeImgLightbox}
            slides={[
              {
                type: "image",
                src: imgUrl || "",
              },
            ]}
            carousel={{
              finite: true,
              padding: 0,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Feed;
