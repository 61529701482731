import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ArtDetailImg from "../../assets/images/art-detail.png";
import Paragraph from "../../common/Paragraph";
import DummyProfile from "../../assets/images/dummy_profile.png";
import Button from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { paths } from "../../routes/Paths";
import { useSelector } from "react-redux";
import { tokenSelector } from "../../redux/slices/userSlice";

function ArtRoomDetail() {
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const handleBuyNowClick = () => {
    const urlWithToken = `${paths.web.checkout}?token=${token}`;
    navigate(urlWithToken);
  };
  return (
    <section>
      <div className="container">
        <div className="pt-4">
          <Breadcrumb />
        </div>
        <div className="md:py-[50px] py-[30px]">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="col-span-1">
              <div className="bg-light-grey w-full rounded-[20px] flex items-center justify-center">
                <img
                  src={ArtDetailImg}
                  alt="art-detail"
                  className="inline-block"
                />
              </div>
            </div>
            <div className="col-span-1">
              <h1 className="md:text-[32px] sm:leading-[31px] text-[28px] leading-[27px] font-semibold text-site-black mb-4">
                Hiboux sur I'arbre
              </h1>
              <div className="flex items-center gap-2 mb-3">
                <div className="md:w-[60px] md:h-[60px] w-[40px] h-[40px] rounded-full flex-shrink-0 overflow-hidden">
                  <img src={DummyProfile} alt="profile-photo" />
                </div>
                <Paragraph text16 className="font-medium">
                  <span className="text-site-dark-black/40 ">by</span> @Aena
                  Gilbert
                </Paragraph>
              </div>
              <Paragraph text18 className="mb-4 font-normal">
                Hey! I would like to share latest abstract piece
              </Paragraph>
              <Paragraph text18 className="mb-2 font-normal">
                <span className="font-medium">Art | </span>
                60*60cm
              </Paragraph>
              <Paragraph text18 className="mb-4 font-normal">
                <span className="font-medium">Available Piece | </span>
                8/10
              </Paragraph>
              <Paragraph text32 className="md:mb-[30px] mb-5">
                $230.00
              </Paragraph>
              <Button
                primary
                className="w-full md:mb-[30px] mb-5"
                onClick={handleBuyNowClick}
              >
                Buy Now
              </Button>
              <Paragraph text20 className="font-medium mb-3">
                Description
              </Paragraph>
              <Paragraph text18 className="font-normal !text-dark-grey mb-4">
                Ethereal Reverie is an abstract art piece that transcends
                conventional boundaries, inviting viewers into a realm where
                imagination reigns supreme.
              </Paragraph>
              <ul className="flex items-center lg:gap-[65px] md:gap-7 sm:gap-5 gap-3">
                <li>
                  <Paragraph text18 className="mb-[10px]">
                    Weight
                  </Paragraph>
                  <Paragraph text18 className="">
                    0.6 kg
                  </Paragraph>
                </li>
                <li>
                  <Paragraph text18 className="mb-[10px]">
                    Width
                  </Paragraph>
                  <Paragraph text18 className="">
                    60 cm
                  </Paragraph>
                </li>
                <li>
                  <Paragraph text18 className="mb-[10px]">
                    Height
                  </Paragraph>
                  <Paragraph text18 className="">
                    60 cm
                  </Paragraph>
                </li>
                <li>
                  <Paragraph text18 className="mb-[10px]">
                    Depth
                  </Paragraph>
                  <Paragraph text18 className="">
                    1 cm
                  </Paragraph>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArtRoomDetail;
