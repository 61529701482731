import React from "react";
import Paragraph from "../../common/Paragraph";
import { FaMapMarkerAlt } from "react-icons/fa";
import DummyProfile from "../../assets/images/dummy_logo.png";
import SlidingTabBar from "../../common/SlidingTabBar";
import About from "../about/About";
import Feed from "../feed/Feed";
import { Tab } from "../../interface/Tabs";
import ArtRoom from "../art-room/ArtRoom";
import { useSelector } from "react-redux";
import { profileSelector } from "../../redux/slices/profileSlice";

function Profile() {
  const profileData = useSelector(profileSelector);

  const tabs: Tab[] = [
    { id: "feed", name: "Feed", content: <Feed /> },
    { id: "art_room", name: "Art Room", content: <ArtRoom /> },
  ];
  console.log("profileData", profileData);

  const getProfileImage = (url?: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url && url !== defaultAvatarUrl ? url : DummyProfile;
  };

  return (
    <>
      <section className="md:pt-[50px] pt-[30px]">
        <div className="container md:pb-10 pb-[30px] border-b border-medium-grey">
          <div className="grid grid-cols-12 lg:gap-6 sm:gap-4 gap-3 items-center">
            <div className="lg:col-span-3 sm:col-span-4 col-span-12">
              <div className="img-block xl:w-[242px] xl:h-[242px] lg:w-[200px] lg:h-[200px] sm:w-[160px] sm:h-[160px] w-[100px] h-[100px] rounded-full flex-shrink-0 overflow-hidden">
                <img
                  src={getProfileImage(profileData?.avatar)}
                  alt="profile-photo"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="lg:col-span-9 sm:col-span-8 col-span-12">
              <Paragraph text32 className="mb-[6px]">
                @{profileData?.nickname}
              </Paragraph>
              <Paragraph
                text18
                className="flex items-center leading-[17px] gap-1 mb-6 "
              >
                <span>
                  <FaMapMarkerAlt size={18} />
                </span>
                {profileData?.country?.name}
              </Paragraph>
              <div className="flex items-center gap-[30px] mb-[18px]">
                <Paragraph text22 className="flex items-center gap-2">
                  {profileData?.followers ? profileData?.followers : 0}
                  <span className="text-[20px] leading-[18px] text-dark-grey font-normal">
                    Followers
                  </span>
                </Paragraph>
                <Paragraph text22 className="flex items-center gap-2">
                  {profileData?.post ? profileData?.post : 0}
                  <span className="text-[20px] leading-[18px] text-dark-grey font-normal">
                    Post
                  </span>
                </Paragraph>
              </div>
              {profileData?.interest && profileData.interest.length > 0 && (
                <>
                  <Paragraph text18 className="mb-2 leading-[17px]">
                    Artist’s Interest
                  </Paragraph>
                  <ul className="flex flex-wrap items-center gap-[10px]">
                    {profileData?.interest?.map((item) => (
                      <li
                        key={item.id}
                        className="border rounded-[20px] border-medium-grey py-[6px] px-4 text-base leading-[15px]"
                      >
                        {item.interest.title}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="md:py-10 py-[30px]">
        <div className="container">
          <div className="flex lg:flex-nowrap flex-wrap lg:gap-6 sm:gap-4 gap-3">
            {/* {profileData?.description && <About profileData={profileData} />} */}
            {<About profileData={profileData} />}

            <div className="w-full">
              <SlidingTabBar tabs={tabs} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
