import React, { useState } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import Paragraph from "../../common/Paragraph";
import ArtDetailImg from "../../assets/images/art-detail.png";
import Button from "../../common/Button";
import { Form, Formik } from "formik";
import Modal from "../../common/Modal";
import PaymentSuccess from "./PaymentSuccess";
import Stepper from "../../common/Stepper";
import BasicInfo from "./BasicInfo";
import PaymentInfo from "./PaymentInfo";
import ShippingAddress from "./ShippingAddress";
import OtherInfo from "./OtherInfo";

function Checkout() {
  const [open, setOpen] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setOpen(!open);
  };
  const closePopup = () => {
    setOpen(false);
  };

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
  };
  // Define form submission handler
  const handleSubmit = (values: typeof initialValues) => {
    console.log("Form submitted with values:", values);
  };

  const steps = [
    { name: "Basic Info.", content: <BasicInfo /> },
    { name: "Payment Info.", content: <PaymentInfo /> },
    { name: "Shipping Address", content: <ShippingAddress /> },
    { name: "Other Info.", content: <OtherInfo /> },
  ];

  return (
    <section>
      <div className="container">
        <div className="pt-4">
          <Breadcrumb />
        </div>
        <div className="md:py-[50px] py-[30px]">
          <h1 className="md:text-[32px] sm:leading-[31px] text-[28px] leading-[27px] font-semibold text-site-black md:mb-[30px] mb-5">
            Checkout
          </h1>
          <div className="grid grid-cols-12 gap-6">
            <div className="lg:col-span-8 col-span-12">
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form>
                  <Stepper
                    steps={steps}
                    showButton={showButton}
                    setShowButton={setShowButton}
                    orientation="horizontal"
                    horizontalPosition={"self-center"}
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                  />
                </Form>
              </Formik>
            </div>
            <div className="lg:col-span-4 col-span-12">
              <div className="bg-light-grey md:p-[30px] p-4 rounded-lg  md:sticky top-[100px]">
                <Paragraph text20 className="font-medium mb-4">
                  Item
                </Paragraph>
                <div className="flex items-start gap-4 pb-5 mb-5 border-b border-medium-grey">
                  <div className="xl:w-[100px] xl:h-[100px] md:w-[70px] md:h-[70px] w-[60px] h-[60px] xl:rounded-[20px] rounded-xl bg-medium-grey overflow-hidden flex-shrink-0">
                    <img src={ArtDetailImg} alt="art-image" />
                  </div>
                  <div>
                    <Paragraph text18 className="">
                      Hiboux sur I’arbre
                    </Paragraph>
                    <Paragraph text18 className="">
                      Art | 60*60cm
                    </Paragraph>
                  </div>
                </div>
                <ul>
                  <li className="flex items-center justify-between gap-3 mb-2">
                    <Paragraph text18 className="font-normal">
                      Price
                    </Paragraph>
                    <Paragraph text18 className="font-normal">
                      $230.00
                    </Paragraph>
                  </li>
                  <li className="flex items-center justify-between gap-3 mb-2">
                    <Paragraph text18 className="font-normal">
                      Shipment fee
                    </Paragraph>
                    <Paragraph text18 className="font-normal">
                      $16.090
                    </Paragraph>
                  </li>
                  <li className="flex items-center justify-between gap-3 mb-2">
                    <Paragraph text18 className="font-normal">
                      Service fee
                    </Paragraph>
                    <Paragraph text18 className="font-normal">
                      $20.000
                    </Paragraph>
                  </li>
                  {/* Different for last child maintain this in loop */}
                  <li className="flex items-center justify-between gap-3 mt-2 mb-4">
                    <Paragraph text18>Total</Paragraph>
                    <Paragraph text18>$266.090</Paragraph>
                  </li>
                </ul>
                <Button primary disabled className="w-full" onClick={openPopup}>
                  Proceed to Checkout
                </Button>
                <Modal open={open} onClose={closePopup} width={"md:w-[424px]"}>
                  <PaymentSuccess onClose={closePopup} />
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Checkout;
