import React from "react";
import classNames from "classnames";

interface ParagraphProps {
  children: React.ReactNode;
  className?: string;
  text32?: boolean;
  text24?: boolean;
  text22?: boolean;
  text20?: boolean;
  text18?: boolean;
  text16?: boolean;
  text14?: boolean;
  text12?: boolean;
  [key: string]: any;
}

function Paragraph({
  children,
  className,
  text32,
  text24,
  text22,
  text20,
  text18,
  text16,
  text14,
  text12,
  ...props
}: ParagraphProps) {
  const classes = classNames({
    "md:text-[32px] sm:leading-[31px] text-[28px] leading-[27px] font-semibold":
      text32,
    "sm:text-[24px] sm:leading-[22px] text-[22px] leading-[20px] font-medium":
      text24,
    "sm:text-[22px] sm:leading-[21px] text-[20px] leading-[19px] font-medium":
      text22,
    "sm:text-[20px] sm:leading-[19px] text-[18px] leading-[17px]": text20,
    "sm:text-[18px] sm:leading-[28px] text-[16px] leading-[24px] font-medium":
      text18,
    "text-[16px] leading-[24px]": text16,
    "text-[14px] leading-[21px] font-semibold": text14,
    "text-[12px] leading-[17px]": text12,
  });

  return (
    <p className={`text-site-black ${classes} ${className ? className : ""}`}>
      {children}
    </p>
  );
}

export default Paragraph;
