import React from "react";
import Paragraph from "../../common/Paragraph";
import FormLabel from "../../common/form-components/FormLabel";
import InputType from "../../common/form-components/InputType";

function BasicInfo() {
  return (
    <div className="md:pt-10 pt-7 w-full">
      <Paragraph text20 className="font-medium mb-4">
        Basic Info.
      </Paragraph>
      <div className="grid grid-cols-12 lg:gap-6 gap-3 mb-4">
        <div className="col-span-12">
          <FormLabel>
            Name<span className="text-site-red">*</span>
          </FormLabel>
          <InputType name="name" placeholder="Enter name" />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            Email<span className="text-site-red">*</span>
          </FormLabel>
          <InputType type="mail" name="email" placeholder="Enter email" />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            Contact number<span className="text-site-red">*</span>
          </FormLabel>
          <div className="flex items-stretch w-full border rounded-lg focus-within:border-dark-grey">
            <div className="pl-[15px] pr-[10px] py-[11px] border-medium-grey relative before:absolute before:right-0 before:h-5 before:w-[1px] before:bg-medium-grey before:rounded-[2px] ">
              <select
                name="phone_country_code"
                className="text-base leading-[15px] font-normal bg-transparent"
              >
                <option value={"+33"}>+33</option>
                <option value={"+91"}>+91</option>
              </select>
            </div>
            <InputType
              type={"number"}
              fullWidth
              name={"phone_number"}
              placeholder={"Enter contact number"}
              className={"border-0"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicInfo;
