import React, { useState } from "react";
import Paragraph from "./Paragraph";
import { HiArrowLeft } from "react-icons/hi2";
import Modal from "./Modal";
import Button from "./Button";
// import SendAllOrders from '../../pages/cart/SendAllOrders'

interface Step {
  name: string;
  content: React.ReactNode;
}

interface StepperProps {
  steps: Step[];
  orientation?: "horizontal" | "vertical";
  horizontalPosition?: string;
  verticalPosition?: string;
  showButton: boolean;
  setShowButton: React.Dispatch<React.SetStateAction<boolean>>;
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const Stepper = ({
  steps,
  orientation = "horizontal",
  horizontalPosition,
  verticalPosition,
  showButton,
  setShowButton,
  setShowPopup,
  showPopup,
}: StepperProps) => {
  // const [isOpen, setIsOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0);
  const isHorizontal = orientation === "horizontal";
  const closePopup = () => {
    setShowPopup(false);
  };

  // const handleChangeCurrentStep = (step) => {
  //   setCurrentStep(step);
  // };

  return (
    <div
      className={`flex ${isHorizontal ? "flex-col" : "flex-row"} items-start`}
    >
      <div
        className={`flex w-full ${isHorizontal ? "flex-row" : "flex-col"} ${
          horizontalPosition ? horizontalPosition : ""
        } items-center sm:gap-4 gap-2`}
      >
        {steps?.map((step, index) => (
          <div
            key={index}
            className="flex items-center sm:gap-3 gap-1 xs:w-1/4 relative z-0"
          >
            <div className="flex items-start sm:gap-3 gap-1 flex-col">
              <div
                className={`flex items-center justify-center w-[26px] h-[26px] rounded-full font-sm leading-[17px] 
                  ${
                    currentStep === index
                      ? "bg-site-yellow text-site-black"
                      : currentStep > index
                      ? "bg-site-yellow text-site-black" // Completed steps
                      : "bg-light-grey text-medium-grey"
                  }`}
              >
                {index + 1} {/* Display the step number */}
              </div>
              <Paragraph
                className={`${
                  currentStep === index
                    ? "text-site-black"
                    : currentStep > index
                    ? "text-site-black"
                    : "text-site-grey"
                } font-medium md:text-left text-center sm:text-base sm:leading-6 text-xs leading-[18px]`}
              >
                {step?.name}
              </Paragraph>
            </div>
            {index !== steps.length - 1 && (
              <div
                className={`border absolute top-[10px] left-[26px] -z-[1]  ${
                  isHorizontal
                    ? "border-t-[3px] w-full"
                    : "border-l-[3px] md:h-20 h-10"
                } 
                ${
                  currentStep > index
                    ? "border-site-yellow"
                    : "border-light-grey"
                }`}
              ></div>
            )}
          </div>
        ))}
      </div>

      {steps[currentStep].content}

      <div className="mt-30 flex justify-between w-full">
        {currentStep !== 0 && (
          <div className="flex items-center gap-3">
            <Button
              className={"font-semibold !p-0"}
              onClick={() => {
                setCurrentStep((prev) => Math.max(prev - 1, 0));
                setShowButton(true);
              }}
            >
              Previous
            </Button>
          </div>
        )}
        {currentStep === steps.length - 1
          ? ""
          : showButton && (
              <Button
                className={"justify-self-end ms-auto"}
                primary
                onClick={() =>
                  setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))
                }
                disabled={currentStep === steps.length - 1}
              >
                {/* {steps[currentStep + 1].name} */}
                Next
              </Button>
            )}
      </div>
      {/* <Modal open={showPopup} header headingText={'Send All Orders'} onClose={closePopup} width={'md:w-[660px]'}>
        <SendAllOrders onClose={closePopup} handleChangeCurrentStep={handleChangeCurrentStep} />
      </Modal> */}
    </div>
  );
};

export default Stepper;
