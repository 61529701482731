import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/userSlice";
import siteLoaderReducer from "./slices/siteLoaderSlice";
import toastReducer from "./slices/toastSlice";
import profileReducer from "./slices/profileSlice";

const persistConfig = {
  key: "Artgapi_Web",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  user: userReducer,
  siteLoader: siteLoaderReducer,
  toast: toastReducer,
  profile: profileReducer,
});

export default persistReducer(persistConfig, rootReducer);
