import React from "react";
import classNames from "classnames";

// Define the interface for the Button props
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  primary?: boolean;
  secondary?: boolean;
  onlyIcon?: boolean;
  lightBlueBg?: boolean;
  greyBg?: boolean;
  disabled?: boolean;
}

// Use the interface in the function component
function Button({
  children,
  className,
  primary,
  secondary,
  onlyIcon,
  lightBlueBg,
  greyBg,
  disabled,
  ...props
}: ButtonProps) {
  const classes = classNames(
    "capitalize rounded-[50px] inline-flex items-center justify-center gap-[10px] transition-all duration-700 ease-in-out motion-reduce:transition-none motion-reduce:hover:transform-none text-base leading-[16px] md:px-[30px] md:py-[15px] px-[20px] py-[12px] font-semibold",
    {
      "bg-site-yellow text-site-black hover:text-white hover:bg-site-black hover:border-medium-grey":
        primary,
      "bg-[#fff] text-site-black hover:text-white hover:bg-primary-blue":
        secondary,
      "bg-light-blue text-primary-blue hover:text-white hover:bg-primary-blue focus:text-white focus:bg-primary-blue":
        lightBlueBg,
      "bg-light-grey text-site-black": greyBg,
      "w-10 h-10 !px-0 !py-0 border-0": onlyIcon,
      "opacity-40": disabled,
    }
  );

  return (
    <button className={`${className ? className : ""} ${classes}`} {...props}>
      {children}
    </button>
  );
}

export default Button;
