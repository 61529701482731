import React, { useState } from "react";
import Paragraph from "../../common/Paragraph";
import RadioButton from "../../common/form-components/RadioButton";
import SwitchToggle from "../../common/form-components/SwitchToggle";
import InputType from "../../common/form-components/InputType";

function OtherInfo() {
  const [isInsured, setIsInsured] = useState(false);
  return (
    <div className="md:pt-10 pt-7 w-full">
      <Paragraph text20 className="font-medium mb-4">
        Carrier
      </Paragraph>
      <div className="grid grid-cols-12 lg:gap-6 gap-3">
        <div className="md:col-span-6 col-span-12">
          <div className="border border-medium-grey rounded-lg p-4 flex items-center gap-[10px] cursor-pointer">
            <RadioButton checked />
            <div>
              <Paragraph text14 className="">
                ECOFR
              </Paragraph>
              <Paragraph text16 className="font-medium">
                $16.090
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <Paragraph text20 className="font-medium mb-4 mt-30">
        Insurance
      </Paragraph>
      <div className="grid grid-cols-12 lg:gap-6 gap-3">
        <div className="md:col-span-6 col-span-12">
          <div className="border border-medium-grey rounded-lg p-4 cursor-pointer">
            <SwitchToggle
              rightLabel="I would like to insure my package through delivery (Ad valorem)"
              onChange={() => setIsInsured(!isInsured)}
            />
            {isInsured && (
              <>
                <Paragraph text14 className="!font-medium mb-2 mt-4">
                  Total values of transport-related goods
                </Paragraph>
                <InputType name="insured" disabled value={"200.00"} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherInfo;
