import { API } from "../apiEndPoints/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from "../axios/axiosMiddleware";

export const CheckUser = (data: object) => {
  return axiosPost(`${API.PROFILE.CHECK_USER}`, data);
};

export const getFeedById = (id: number, query?: string) => {
  return axiosGet(`${API.PROFILE.FEED}/${id}/getFeedArtroomData${query || ""}`);
};
