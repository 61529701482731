import React, { useEffect, useState } from "react";
import NotFoundImg from "../../assets/images/404.png";
import Paragraph from "../../common/Paragraph";
import Button from "../../common/Button";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const [isFixed, setIsFixed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust the scroll position as needed
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Header isFixed={isFixed} />
      <main className="min-h-[calc(100vh-138px)] bg-white flex items-center justify-center">
        <div className="container mx-auto ">
          <div className="grid grid-cols-12">
            <div className="card p-6 text-center lg:col-span-6 lg:col-start-4 md:col-span-8 md:col-start-3 col-span-12">
              <img
                src={NotFoundImg}
                alt="page-not-found"
                className="md:mb-[50px] mb-10"
              />
              <h1 className="font-medium mb-4 md:text-[46px] md:leading-[57px] sm:text-[36px] sm:leading-[46px] text-[32px] leading-[40px]">
                There is NOTHING here...
              </h1>
              <Paragraph text20 className="mb-10 leading-[30px]">
                ...maybe the page you’re looking for is not found or never
                existed.
              </Paragraph>
              <Button primary onClick={() => navigate(-1)}>
                Back to home
              </Button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default NotFound;
