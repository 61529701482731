import React from "react";
import Paragraph from "../../common/Paragraph";

function About({ profileData }: any) {
  return (
    <div className="lg:w-[410px] w-full lg:flex-shrink-0">
      <div className="bg-light-grey md:p-[30px] p-5 rounded-xl  sticky top-[120px]">
        <Paragraph text20 className="font-medium mb-[10px]">
          About Artist
        </Paragraph>
        <Paragraph text18 className="text-site-dark-black font-normal">
          {profileData?.description ? profileData?.description : "--"}
        </Paragraph>
      </div>
    </div>
  );
}

export default About;
