import React from "react";

function Footer() {
  return (
    <footer className="bg-primary-blue text-center p-3 text-white">
      <p className="text-sm leading-[22px]">
        © 2024 ArtGapi . All Rights Reserved
      </p>
    </footer>
  );
}

export default Footer;
