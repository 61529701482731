import React from "react";
import Button from "../../common/Button";
import WhiteLogo from "../../assets/images/white-logo.svg";
import { IoCall } from "react-icons/io5";

function Header({ isFixed }: { isFixed: boolean }) {
  return (
    <header
      className={`bg-primary-blue sm:py-4 py-3 w-full z-50 transition-transform duration-300 ${
        isFixed ? "fixed top-0" : "static"
      }`}
    >
      <div className="container">
        <div className="grid grid-cols-12 lg:gap-6 sm:gap-4 gap-3 items-center">
          <div className="md:col-span-3 col-span-6">
            <a href="">
              <img src={WhiteLogo} alt="logo" />
            </a>
          </div>
          <div className="md:col-span-9 col-span-6 text-end">
            <a
              href="tel:+33 700-555-817"
              className="inline-flex items-center justify-end gap-[10px] sm:text-white text-site-black text-[18px] leading-[17px] sm:hover:text-site-yellow transition-all duration-700 sm:bg-transparent bg-white sm:p-0 p-1 sm:rounded-0 rounded-md"
            >
              <IoCall size={24} />{" "}
              <span className="sm:block hidden">+33 700-555-817</span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
