import React, { useState, useRef, useEffect } from "react";

// Define the type for a tab item
interface Tab {
  id: string;
  name: string;
  content: React.ReactNode; // Can be a React component or any other valid React node
}

// Define the props interface
interface SlidingTabBarProps {
  tabs: Tab[];
}

// Define the component function
const SlidingTabBar = (props: SlidingTabBarProps) => {
  const { tabs } = props;
  const tabsRef = useRef<(HTMLElement | null)[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState<number>(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState<number>(0);

  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeTabIndex] as HTMLElement;
      if (currentTab) {
        setTabUnderlineLeft(currentTab.offsetLeft);
        setTabUnderlineWidth(currentTab.clientWidth);
      }
    };

    setTabPosition();
  }, [activeTabIndex, tabs]);

  return (
    <div className="relative flex flex-col">
      <div className="relative inline-flex rounded-[50px] bg-light-grey p-1 flew-row backdrop-blur-sm w-max">
        <span
          className="absolute bottom-0 -z-10 flex overflow-hidden rounded-3xl py-[4px] transition-all duration-300 top-0"
          style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
        >
          <span className=" h-full w-full rounded-3xl bg-site-yellow" />
        </span>
        {tabs.map((tab, index) => {
          const isActive = activeTabIndex === index;

          return (
            <button
              key={tab.id}
              ref={(el) => (tabsRef.current[index] = el)}
              className={`my-auto cursor-pointer select-none rounded-full py-[10px] px-7 text-center text-[16px]
              leading-[15px] font-medium text-site-black `}
              onClick={() => setActiveTabIndex(index)}
            >
              {tab.name}
            </button>
          );
        })}
      </div>
      <div className="mt-10 w-full">
        {tabs[activeTabIndex].content}{" "}
        {/* Render the content of the active tab */}
      </div>
    </div>
  );
};

export default SlidingTabBar;
